<template>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light" id="main-header">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
            <li class="nav-item size-mobile">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
            </li>
            <!-- <li class="nav-item d-none d-sm-inline-block">
                <router-link :to="'/'" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item d-none d-sm-inline-block">
                <router-link :to="'/user'" class="nav-link">User</router-link>
            </li> -->
            <li class="nav-item size-website">
                <a class="nav-link" style="color: black;">
                    <h5 id="page-title-content">Trang chủ</h5>
                </a>
            </li>
        </ul>

        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto">

            <!-- Notifications Dropdown Menu -->
            <li class="nav-item dropdown">
                <div class="image-avatar" data-toggle="dropdown">
                    <b class="mr-2 mt-1">{{ userLogin.first_name }} {{ userLogin.last_name }}</b>
                    <b-avatar v-bind:src="userLogin.avatar" class="elevation-2" size="33px" variant="info" />
                </div>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a class="dropdown-item link-click" v-on:click="signOut()">
                        <i class="fas fa-sign-out-alt mr-2"></i><span>Đăng xuất</span>
                    </a>
                </div>
            </li>

        </ul>
        <!-- <table class="table-attendance-fixed" id="table-attendance-fixed" style="display: none;">
            <tr style="background: #f7f7f7;">
                <td colspan="5" style="border-top: none;">
                    <div id="id-attendance-note" class="float-right pr-2" style="color: #7f7f7f;">
                        <i>Đã điểm danh <span class="text-dark">1</span> trên tổng
                            <span class="text-dark">2</span> học viên</i>
                    </div>
                </td>
            </tr>
        </table> -->
    </nav>
</template>

<script>
import { removeAdminAuthen, getAdminAuthen } from "../../common/storage";

export default {
    data: function () {
        return {
            userLogin: {}
        };
    },
    methods: {
        signOut: function () {
            removeAdminAuthen()
            location.reload()
        }
    },
    mounted() {
        const user = getAdminAuthen();
        if (user) this.userLogin = user;
        this.setTitle(this.$route.name);
    },
    watch: {
        $route() {
            this.setTitle(this.$route.name);
        }
    }
};
</script>
<style>
.table-attendance-fixed {
    position: absolute;
    bottom: -41px;
    width: 98.1%;
    left: 15px;
}
</style>